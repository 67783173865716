import EService from '~/parts/core/services/eService';
import { type FileModel } from "~/parts/medias";
class FileService extends EService {

    constructor(useApiKey: boolean = false) {
        super(useApiKey);
    }

    async get(id: number) {
        const file = await this.http.get<FileModel>(`files/GetModel/${id}`);
        return file;
    }

    async getMany(ids: Array<number>) {
        const query = this.serializeArrayQuery("ids", ids);
        const files = await this.http.get<Array<FileModel>>(`files/GetModels/?${query}`);
        return files;
    }

    async uploadFile<TResponse>(files: Array<File> | File, path: string | null = null) {
        const formData = new FormData();
        const filesToUpload = Array.isArray(files) ? files : [files];
        filesToUpload.forEach(file => {
            formData.append("formFiles", file, file.name);
        });

        const response = await this.http.post<TResponse, FormData>(path ?? 'files/upload/', formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return response;
    }

    async uploadFileWithProgress(file: FormData,
        uploadProgress: (e: ProgressEvent) => void) {
        const response = await this.http.postWithProgress<boolean>('files/upload/', file, uploadProgress);
        return response;
    }

    downloadBlobFile(data: BlobPart, type: string, fileName: string, targetElement: HTMLElement) {
        var blob = new Blob([data], { type });
        if (window.navigator && window.navigator.hasOwnProperty("msSaveOrOpenBlob")) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        }
        else {
            var elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = fileName;
            targetElement.appendChild(elem);
            elem.click();
            targetElement.removeChild(elem);
        }
    }
}

export default FileService;
